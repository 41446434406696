<script>
import SelectField from '@/components/general/SelectField'
import ModalInviteMember from '@/components/workspace/businessWorkspace/WorkspaceManagement/ModalInviteMember'
import ModalChangeMemberProfile from '@/components/workspace/businessWorkspace/WorkspaceManagement/ModalChangeMemberProfile.vue'
import ConfirmationModal from '@/components/general/ConfirmationModal'

export default {
  name: 'MemberSubmenuTabMember',
  components: {
    ConfirmationModal,
    ModalChangeMemberProfile,
    ModalInviteMember,
    SelectField
  },
  data () {
    return {
      selectedMember: false,
      showModalInvite: false,
      deletitionMember: null,
      sortOptions: [{ text: this.$t('global:sort.recent'), value: 'createdAt' }, { text: this.$t('global:sort.alphabetic'), value: 'name' }],
      header: [
        { text: this.$t('workspace.member.modal.header:name'), value: 'name', width: '50%' },
        { text: this.$t('workspace.member.modal.header:profile'), value: 'profile' },
        { text: this.$t('workspace.member.modal.header:actions'), value: 'action', width: '80px' }
      ],
      textualSearch: null,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: 'createdAt',
        totalItems: -1
      }
    }
  },
  computed: {
    membersList () {
      return this.$store.getters.getWorkspaceMembers[this.selectedWorkspace.id] || []
    }
  },
  watch: {
    'pagination.sortBy' () {
      this.loadMembers()
    }
  },
  methods: {
    updatePage (page) {
      this.pagination.page = page
      this.loadMembers()
    },
    showPaginationText ({ pageStart, pageStop, itemsLength }) {
      return `${pageStart}-${pageStop} ${this.$t('skill.modal.pagination.preposition')} ${itemsLength}`
    },
    deleteMember (member) {
      this.deletitionMember = member
    },
    removeMember () {
      const payload = {
        workspaceId: this.selectedWorkspace.lxID,
        userId: this.deletitionMember.owner.userId
      }

      this.$store.dispatch('attemptRemoveUserFromWorkspace', payload)
        .then(() => {
          const name = this.deletitionMember.name
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('remove.user.from.workspace:success', { name })
          })
        })
        .finally(() => {
          this.deletitionMember = null
          this.loadMembers()
        })
    },
    closeMemberManagement () {
      this.loadMembers()
      this.selectedMember = false
    },
    loadMembers () {
      const payload = {
        workspaceId: this.selectedWorkspace.lxID,
        page: this.pagination.page,
        sortBy: this.pagination.sortBy,
        limit: this.pagination.itemsPerPage,
        order: this.pagination.sortBy === 'createdAt' ? 'DESC' : 'ASC',
        filter: {
          textualSearch: this.textualSearch
        }
      }
      this.$store.dispatch('attemptGetWorkspaceMembers', payload)
        .then((data) => {
          this.pagination.totalItems = data.total
        })
    },
    search () {
      this.debounceEvent(() => {
        this.updatePage(1)
      }, 400)
    }
  },
  created () {
    this.loadMembers()
  }
}
</script>
<template>
  <div class="member-submenu-member--container">
    <div class="workspace-management--members__actions">
      <v-btn
        v-if="userCanManage(selectedWorkspace.id)"
        outlined
        class="btn"
        color="#1200D3"
        @click="showModalInvite = true"
      ><v-icon>mdi-plus</v-icon>{{ $t('workspace.management.submenu.member:invite') }}</v-btn>
      <div class="filter--wrapper">
        <v-text-field v-model="textualSearch" :placeholder="$t('global:search')" prepend-inner-icon="mdi-magnify" outlined hide-details="auto" @input="search" clearable></v-text-field>
        <select-field class="order-by--select" outlined small v-model="pagination.sortBy" :items="sortOptions" />
      </div>
    </div>
    <div class="workspace-management--members__list">
      <v-data-table
        class="members-table--wrapper"
        :headers="header"
        :items="membersList"
        :items-per-page="pagination.itemsPerPage"
        :hide-default-footer="membersList.length === 0"
        :page="pagination.page"
        @update:page="updatePage"
        :server-items-length="pagination.totalItems"
        disable-sort
        hide-default-header
        :footer-props="{ 'items-per-page-options': [pagination.itemsPerPage] }"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="h in headers" :key="h.value" :width="h.width">
              <span class="table-header">{{h.text}} </span>
            </th>
          </tr>
          </thead>
        </template>
        <template
          v-if="membersList.length > 0"
          v-slot:body
        >
          <tbody>
          <tr
            v-for="(member, index) in membersList"
            :key="index"
            class="member-table--line"
          >
            <td class="text-left overflow-text">
              <div class="workspace-members--user-info">
                <v-img class="workspace-members--user-picture" :src="member.imageUrl || defaultAvatarUrl('profile')"></v-img>
                <span class="workspace-members--user-name">{{ member.name }} {{ member.lastName }}</span>
              </div>
            </td>
            <td class="text-left">{{ member.role ? $t(`modal.invite.member:roles.${member.role}`) : '-' }}</td>
            <td class="text-center members-actions">
              <v-icon @click="selectedMember = member" class="edit-profile--btn" color="#1200D3" :disabled="Boolean(!userCanManage(selectedWorkspace.id))">mdi-pencil</v-icon>
              <v-icon @click="deleteMember(member)" class="delete-member--btn" color="#E83651" :disabled="!userCanManage(selectedWorkspace.id)">mdi-delete</v-icon>
            </td>
          </tr>
          </tbody>
        </template>
        <template
          v-if="membersList.length === 0"
          v-slot:no-data
        >
          {{ $t('members.modal.table:no.members.found') }}
        </template>
        <template v-slot:[`footer.page-text`]="data">
          {{ showPaginationText(data) }}
        </template>
      </v-data-table>
    </div>
    <modal-invite-member :show="showModalInvite" @close="showModalInvite = false"/>
    <modal-change-member-profile v-if="selectedMember" :memberToBeEdited="selectedMember" @close="closeMemberManagement"/>
    <confirmation-modal
      :show="deletitionMember && !!deletitionMember.name"
      :title="$t('members.submenu.removal:title')"
      :description="$t('members.submenu.removal:description', { name: `${deletitionMember && deletitionMember.name} ${deletitionMember && deletitionMember.lastName}` })"
      :confirmText="$t('members.submenu.removal:title')"
      :btnConfirmText="false"
      @confirm="removeMember"
      :cancelText="$t('global:cancel')"
      @cancel="deletitionMember = null"
    ></confirmation-modal>
  </div>
</template>
<style lang="scss">
.member-submenu-member--container {
  .workspace-management--members__actions {
    .filter--wrapper {
      display: flex;
      margin-top: 20px;
      .v-input {
        &.v-text-field--outlined {
          margin-right: 16px;
          background: white;
          .v-label {
            top: 11px;
          }
          >.v-input__control {
            > .v-input__slot {
              min-height: 40px;
            }
          }
          &:not(.v-text-field--single-line) {
            .v-select__selections {
              padding: 4px 0;
              .v-chip {
                margin: 1px 0 -1px !important;
              }
            }
          }
        }
      }
      .v-text-field--enclosed {
        .v-input__append-inner,
        .v-input__prepend-inner {
          margin-top: 8px;
        }
      }
      .v-input.v-text-field:not(.v-text-field--outlined) {
        padding-top: 1px;
        >.v-input__control {
          > .v-input__slot:before {
            bottom: -1px;
          }
        }
      }
    }
  }
  .edit-profile--btn {
    cursor: pointer;
  }
  .members-actions {
    display: flex;
    align-items: center;
    .v-icon {
      height: 24px;
    }
    .delete-member--btn {
      margin-left: 12px;
    }
  }
  .members-table--wrapper {
    margin-right: 12px;
    margin-bottom: 120px;
  }
}
</style>
