<script>
import InputField from '@/components/general/InputField'
export default {
  name: 'ModalInviteMember',
  components: { InputField },
  props: {
    memberToBeEdited: {
      type: Object
    }
  },
  data () {
    return {
      selectedRole: 'member',
      availableRoles: [
        'member',
        'administrator',
        'content_creator'
      ]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      const payload = {
        workspaceId: this.selectedWorkspace.lxID,
        userId: this.memberToBeEdited.owner.userId,
        body: {
          role: this.selectedRole
        }
      }
      this.$store.dispatch('attemptChangeMemberProfile', payload)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('modal.change.profile.member:feedback.success')
          })
        })
        .finally(() => {
          this.close()
        })
    }
  },
  created () {
    this.selectedRole = this.memberToBeEdited.role || 'member'
  }
}
</script>
<template>
  <v-dialog :value="true" width="620" persistent>
    <v-card class="modal-change-member-profile--container">
      <div class="modal-invite-member--header__wrapper">
        <h5 class="h7">{{ $t('modal.change.member.profile:title') }}</h5>
        <v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="modal-invite-member--body">
        <input-field outlined readonly :label="$t('home:header.name')" :value="memberToBeEdited.name"/>
        <input-field outlined readonly :label="$t('global:email')" :value="memberToBeEdited.email"/>
        <div class="modal-invite-member--roles__wrapper">
          <v-radio-group v-model="selectedRole">
            <v-radio
              class="modal-invite-member--custom-role-option"
              v-for="(role, index) in availableRoles"
              :key="index"
              :label="$t(`modal.invite.member:roles.${role}`)"
              :value="role"
              color="#1200D3"
            >
              <template v-slot:label>
                <div class="modal-invite-member--custom-role-label">
                  <h5 class="h8">{{ $t(`modal.invite.member:roles.${role}`) }}</h5>
                  <p class="body--1">{{ $t(`modal.invite.member:roles.${role}.description`) }}</p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div class="modal-invite-member--actions">
          <v-btn
            text
            color="#1200D3"
            @click="close"
            class="text-secondary mr-3 bold"
          >
            {{ $t('global:cancel') }}
          </v-btn>
          <v-btn
            class="bold"
            dark
            color="#1200D3"
            @click="submit"
          >
            {{ $t('global:save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-change-member-profile--container {
  padding: 17px 20px;
  text-align: left;
  .modal-invite-member--header__wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-invite-member--body {
    margin-top: 24px;
    .modal-invite-member--description {
      margin: 12px 0 24px;
      color: $neutral-medium;
    }
    .modal-invite-member--custom-member {
      display: flex;
      align-items: center;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
    .modal-invite-member--custom-member-chip {
      display: flex;
      align-items: center;
      height: 32px;
      color: $neutral-darkest;
      background-color: $neutral-light;
      border-radius: 100px;
      padding-right: 16px;
      margin-right: 4px;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
  }
  .modal-invite-member--roles__wrapper {
    > .v-input {
      margin: 0;
    }
    .modal-invite-member--custom-role-option.v-radio {
      margin-bottom: 24px;
    }
    .modal-invite-member--custom-role-label {
      p.body--1 {
        margin: 0;
      }
    }
  }
  .modal-invite-member--actions {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 768px) {
    .modal-invite-member--actions {
      .v-btn {
        width: calc(50% - 6px);
      }
    }
  }
}
</style>
